import type { Response } from 'express';
import type { NextRouter } from 'next/router';

import { isBrowser, isDev } from '../helper';

export const redirect = (
  redirectUrl: URL | string,
  res?: Response,
  router?: NextRouter,
  status?: number
): Promise<boolean> => {
  // eslint-disable-next-line no-console
  isDev && console.log(`\x1b[36m[dx-guests-ui]\x1b[0m - redirect - ${redirectUrl.toString()}`);
  if (res && !res.headersSent) {
    res.redirect(status || 303, redirectUrl.toString());
    res.end();
  }

  if (isBrowser) {
    if (router) {
      return router.push(redirectUrl);
    }

    window.location.href = redirectUrl.toString();
  }

  return new Promise(() => false);
};
